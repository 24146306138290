<template>
  <div class="bg-white shadow p-3 mb-6.5 rounded">
    <div class="absolute top-0 right-0 gap-2 flex">
      <Button
        text="Add New"
        type="primary-white"
        size="medium"
        @click.native="addNewCompetency"
      />
      <Button
        text="Save and Continue"
        type="primary"
        size="medium"
        @click.native="copyCompetencies"
        :disabled="selectedCompetenciesLength === 0"
      />
    </div>
    <p class="font-barlow text-mdl font-bold text-grey-light mb-2 ">
      Platform Competencies
    </p>
    <p class="text-mdh font-normal text-grey-dark-1 max-w-3/4">
      To create a new competency select Add New.
    </p>
    <p class="text-mdh font-normal text-grey-dark-1 max-w-3/4">
      To create a similar competency structure select the competency you wish to
      clone and select Save and Continue.
    </p>
    <p class="text-mdh font-normal text-grey-dark-1 max-w-3/4 mb-1.5">
      Once you save and continue you will be able to edit the competency
      information and add or remove sub-competencies.
    </p>
    <div class="flex flex-row items-end justify-between">
      <div>
        <InputField
          placeholder="Search by name"
          label="Filter by"
          :onChange="setKeyword"
          leftIcon
          class="mb-2 w-inputField"
          data-testid="name-field"
        />
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} Competencies
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-1 rounded">
        <button
          class="w-60 p-0.4 gap-0.4 items-center justify-center flex"
          @click="toggleAll"
        >
          <Checkbox :active="selectAll" />
        </button>
        <div
          class="flex flex-row items-center focus:outline-none w-90 ml-1"
        >
            <p class="text-sm-2 text-grey-light ml-5.5">NO.</p>
        </div>
        <div class="flex flex-grow w-1/4 mr-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('competency', 1)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">COMPETENCY NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-2/5 mr-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('subCompetency', 2)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">SUB-COMPETENCY</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5 ml-auto items-center p-0.5">
          <button 
            class="flex flex-row items-center focus:outline-none" 
            @click="sort('assessment', 3)"
            data-testid="header-2"
            >
            <p class="text-sm-2 text-grey-light mr-0.4">ASSESSMENT</p>
            <img 
              src="../../../../assets/img/delta-table/caret.svg" 
              class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null" 
              />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 560px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import debounce from "lodash/debounce";
import VirtualList from "vue-virtual-scroll-list";

import ListItem from "./ListItem.vue";
import Checkbox from "../../../../components/Checkbox";

export default {
  name: "CompetenciesTable",
  components: { VirtualList, Checkbox },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    selectAll: false,
  }),
  computed: {
    ...mapState({
      competencies: (state) => state.companies.competencies,
      productDetails: (state) => state.products.productDetails,
    }),
    selectedCompetenciesLength() {
      return this.mappedList.filter((item) => item.isSelected).length;
    },
    selectedCompetenciesIds() {
      return this.mappedList
        .filter((item) => item.isSelected)
        .map((item) => item.id);
    },
  },
  watch: {
    keyword() {
      this.mappedList = [];
      this.selectAll = false;
      this.getCompetencies(this.keyword);
    },
    selectedCompetenciesLength(value) {
      return value === this.mappedList.length && value !== 0
        ? (this.selectAll = true)
        : (this.selectAll = false);
    },
  },
  methods: {
    ...mapActions({
      getCoreCompetencies: "companies/getCoreCompetencies",
      resetCompetencyDetails: "products/resetCompetencyDetails",
      openModal: "modals/openModal",
    }),
    async getCompetencies(keyword = "") {
      await this.getCoreCompetencies({ pageNumber: 1, pageSize: 999, keyword });
      this.copyToLocal();
    },
    async copyCompetencies() {
      const payload = {
        competenciesToCloneIds: this.selectedCompetenciesIds,
        surveyId: this.productDetails.id,
      };
      this.openModal({
        modal: "copyCompetenciesModal",
        props: { payload },
      });
    },
    copyToLocal() {
      this.mappedList = this.competencies
        .filter((item) => item.active && item.survey)
        .map((item) => ({ ...item, isSelected: false }));
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
    },
    toggleItem(id) {
      this.mappedList.map((item) =>
        item.id === id ? (item.isSelected = !item.isSelected) : null
      );
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "number":
          this.mappedList.reverse();
          break;
        case "competency":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * a.name.localeCompare(b.name)
          );
          break;
        case "subCompetency":
          const getSubcompetenciesString = (item) => item.subCompetencies.map(x => x.name).join(', ');
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              (getSubcompetenciesString(a).localeCompare(getSubcompetenciesString(b)))
          );
          break;
        case "assessment":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.survey.localeCompare(b.survey)
          );
          break;
        default:
          break;
      }
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
    addNewCompetency() {
      const id = this.$route.params.productId;
      this.resetCompetencyDetails();
      this.$router.push(`/products/${id}/new-competency`);
    },
  },
  mounted() {
    this.getCompetencies();
  },
};
</script>
