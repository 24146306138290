<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-1 items-center">
      <button
        class="flex w-60 p-0.4 justify-center"
        @click="() => toggle(source.id)"
      >
        <Checkbox :active="source.isSelected" />
      </button>
      <div class="flex w-90 items-center mr-2">
        <div
          class="competency-label rounded-full ml-1.5 mr-3.5"
          :style="{ 'background-color': `${source.color}` }"
        ></div>
        <p class="font-normal text-md text-grey-dark-2 w-auto">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4 mr-1 pl-0.5">
        <p class="flex flex-grow text-md text-grey-dark-1 font-semibold">
          {{ source.name.replaceAll("&nbsp;", " ") }}
        </p>
      </div>
      <div class="flex flex-grow w-2/5 mr-1 pl-0.5">
        <p class="text-md text-grey-dark-2">
          <ExpandMore :data="subComps" showHideButton/>
        </p>
      </div>
      <div class="flex flex-grow w-1/5 ml-auto pl-0.2">
        <p class="text-md text-grey-dark-2">
          {{ source.survey.replaceAll("&nbsp;", " ") }}
        </p>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import ExpandMore from "../../../../components/ExpandMore/ExpandMore.vue";
import Checkbox from "../../../../components/Checkbox";
export default {
  components: { ExpandMore, Checkbox },
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
  },
  computed: {
    subComps() {
      return this.source.subCompetencies.map((item) => item.name);
    },
  },
};
</script>
<style lang="scss">
.competency-label {
  min-width: 13px;
  height: 13px;
  width: 13px;
}
</style>
