<template>
  <div class="relative">
    <p class="text-xl font-headers mb-1.5 font-bold text-grey-dark-2">
      Competencies
    </p>
    <CompetenciesTable />
  </div>
</template>
<script>
import CompetenciesTable from "../components/CompetenciesTable";

export default {
  name: "ProductCompetencies",
  components: { CompetenciesTable },
};
</script>
